import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import {
  FirstTimeLessonAppointmentAvailability,
  LessonAppointmentAvailability,
} from 'common/dist/infrastructure/modules/appointment/interfaces/AvailabilityTypes';
import { useInjection } from 'inversify-react';
import { useEffect } from 'react';

import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from './useCaseNavigateBookingWizard';

export enum GetInstructorsError {
  Unknown = 'unknown',
  Unauthorized = 'unauthorized',
}

export enum FirstTimeLessonError {
  Unknown = 'unknown',
  Unauthorized = 'unauthorized',
}

export const GetInstructorsAdapter = Symbol('GetInstructorsAdapter');

export type IGetInstructorsAdapter = InjectableHook<
  AsyncHookResult<Array<LessonAppointmentAvailability>, GetInstructorsError> & {
    saveSelectedInstructorToStore: (staffId: number) => void;
    setFlowTypeToLesson: () => void;
    flowType: string | undefined;
    selectFirstLessonFlow: () => void;
    clearPartySize: () => void;
    firstTimeLessonsLoading: boolean;
    firstTimeLessons: FirstTimeLessonAppointmentAvailability[] | undefined;
    firstTimeLessonError: FirstTimeLessonError | undefined;
    getFirstTimeLessonAppointmentAvailabilities: ({
      locationId,
      startDateTime,
      endDateTime,
      email,
    }: {
      locationId: string;
      startDateTime: string;
      endDateTime: string;
      email?: string;
    }) => void;
    getInstructors: ({
      locationId,
      startDateTime,
      endDateTime,
      email,
    }: {
      locationId: string;
      startDateTime: string;
      endDateTime: string;
      email?: string;
    }) => void;
  }
>;

const useCaseSelectInstructor = (currentStep?: WizardStep) => {
  const adapter = useInjection<IGetInstructorsAdapter>(GetInstructorsAdapter);

  // Always call the hook, even if you don't need it right away
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );

  const {
    error,
    result,
    inProgress,
    firstTimeLessons,
    firstTimeLessonsLoading,
    firstTimeLessonError,
    saveSelectedInstructorToStore,
    setFlowTypeToLesson,
    selectFirstLessonFlow,
    clearPartySize,
    getFirstTimeLessonAppointmentAvailabilities,
    getInstructors,
    flowType,
  } = adapter();

  const handleNextStep = (staffId: number | null, isFirstTimeLesson?: boolean) => {
    clearPartySize();
    if (isFirstTimeLesson) {
      selectFirstLessonFlow();
    } else if (staffId) {
      saveSelectedInstructorToStore(staffId);
    }

    // Conditionally use the navigation only if `currentStep` is provided
    if (bookingWizardNavigation && currentStep) {
      bookingWizardNavigation.redirectToNextStep(currentStep);
    }
  };

  useEffect(() => {
    // Ensure the flow type is set to Lesson if it hasn't been already
    if (
      (!flowType || flowType !== BookingWizardFlowTypes.Lesson) &&
      currentStep !== WizardStep.chooseLessonTime
    ) {
      setFlowTypeToLesson();
    }
  }, [flowType, currentStep, setFlowTypeToLesson]);

  return {
    inProgress,
    error,
    result,
    firstTimeLessons,
    firstTimeLessonsLoading,
    firstTimeLessonError,
    getFirstTimeLessonAppointmentAvailabilities,
    getInstructors,
    nextStep: handleNextStep,
    prevStep: () => {
      if (bookingWizardNavigation && currentStep) {
        bookingWizardNavigation.redirectToPrevStep(currentStep);
      }
    },
  };
};

export default useCaseSelectInstructor;
