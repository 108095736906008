import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { format, formatDuration } from 'date-fns';
import { useFormikContext } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';

import useCasePreOrderFood, {
  PreOrderFoodFormValues,
} from 'application/modules/bookingWizard/useCases/hooks/useCasePreOrderFood';
import useCaseReservationComplete from 'application/modules/bookingWizard/useCases/hooks/useCaseReservationComplete';
import ContentCard from 'infrastructure/components/ContentCard';
import {
  selectBookingForm,
  selectLocationDetails,
  selectRedirectTo,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { StyledSummaryDivider } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/style';
import FormikInput from 'infrastructure/targets/web/modules/common/FormikInput';
import {
  formatToCurrency,
  getAdapterLocale,
} from 'infrastructure/targets/web/modules/common/helpers';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import FoodLineItem from './FoodLineItem/FoodLineitem';
import { StyledSectionColumn, StyledSummaryDataRow } from './style';

interface IFoodSummaryCard {
  sx?: Record<string, unknown>;
}

const FoodSummaryCard: FC<IFoodSummaryCard> = (props) => {
  const theme = useTheme();
  const t = useTranslationPrefix('pre_order_food.summary');
  const tDialog = useTranslationPrefix('pre_order_food.dialog');
  const {
    isWaiverLoading,
    nextStep: sendWaiver,
    makeAnotherBooking,
  } = useCaseReservationComplete();
  const [total, setTotal] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMinimized, setIsMinimized] = useState(isMobile);
  const { isValid, values } = useFormikContext<PreOrderFoodFormValues>();
  const { reservationDate, startTime, sessionLength } = useAppSelector(selectBookingForm);
  const locationDetails = useAppSelector(selectLocationDetails);
  const redirectTo = useAppSelector(selectRedirectTo);

  const { inProgress, isSuccess, error, handleSavePreOrderFoodData } = useCasePreOrderFood();

  const disabled =
    inProgress || isSuccess || !!error || !isValid || Object.keys(values?.order ?? {}).length === 0;

  const summaryItems = useMemo(
    () => [
      {
        label: 'Location',
        value: locationDetails?.name,
      },
      {
        label: 'Date',
        value:
          reservationDate &&
          format(new Date(reservationDate), 'EEEE, P', {
            locale: getAdapterLocale(),
          }),
      },
      {
        label: 'Time',
        value: startTime && format(new Date(startTime.slice(0, -1)), 'hh:mm aa'),
      },
      {
        label: 'Session Length',
        value:
          sessionLength &&
          formatDuration({ hours: Number(sessionLength) }, { format: ['hours'], zero: true }),
      },
    ],
    [locationDetails?.name, reservationDate, sessionLength, startTime],
  );

  const completeReservation = () => {
    if (redirectTo) {
      window.location.href = `${redirectTo}?food_order_result=success`;
      return;
    }
    makeAnotherBooking();
  };

  useEffect(() => {
    if (values) {
      const _total = Object.values(values.order).reduce((sum, value) => {
        if (value?.price && value?.quantity) {
          return sum + value.price * value.quantity;
        }
        return sum;
      }, 0);
      setTotal(_total);
    }
  }, [values]);

  useEffect(() => {
    if (isSuccess) {
      setOpen(true);
    }
  }, [isSuccess, error]);

  const onSubmit = () => handleSavePreOrderFoodData(values);

  const handleToggleMinimized = () => {
    setIsMinimized(!isMinimized);
  };

  const closeModal = () => {
    setIsMinimized(true);
  };

  return (
    <>
      {/* Minimized View with Button on Mobile */}
      {isMinimized && isMobile && (
        <Button
          type="button"
          onClick={handleToggleMinimized}
          color={'primary'}
          variant={'contained'}
          sx={{
            width: '90%',
            position: 'fixed',
            bottom: 16,
          }}>
          View Cart ({Object.values(values.order).length})
        </Button>
      )}

      {/* Expanded View - Modal for Mobile */}
      {!isMinimized && isMobile && (
        <Dialog
          open={!isMinimized}
          onClose={handleToggleMinimized}
          fullScreen={isMobile}
          PaperProps={{
            sx: {
              margin: '0',
              width: '100%',
              height: '80%',
              top: '20%',
              position: 'absolute',
            },
          }}>
          <Box
            sx={{
              position: 'relative',
              padding: theme.spacing(4),
              backgroundColor: theme.palette.background.default,
            }}>
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}>
              <Button onClick={closeModal}>
                <Close />
              </Button>
            </Box>

            <ContentCard sx={{ padding: theme.spacing(4) }}>
              <Box>
                <Typography variant="h5">{t('reservation_heading')}</Typography>
                {summaryItems?.map((item, idx) => {
                  if (item.label && item.value) {
                    return (
                      <StyledSummaryDataRow key={`${item.label}${item.value}${idx}`}>
                        <Typography variant="body2" color={'text.secondary'}>
                          {item.label}
                        </Typography>
                        <Typography variant="body2" color={'text.primary'}>
                          {item.value}
                        </Typography>
                      </StyledSummaryDataRow>
                    );
                  }
                })}
              </Box>

              <Box sx={{ display: Object.values(values.order).length > 0 ? 'block' : 'none' }}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 4, gap: 3 }}>
                  <Typography variant="h5">{t('cost_heading')}</Typography>
                  {Object.values(values.order)
                    ?.filter((v) => typeof v !== 'undefined')
                    .map((item, idx) => (
                      <FoodLineItem key={idx} {...item} editMode />
                    ))}
                </Box>
                <StyledSummaryDivider />
                <StyledSummaryDataRow sx={{ mt: 0 }}>
                  <Typography variant="h5">{t('total')}</Typography>
                  <Typography variant="h5">{total ? formatToCurrency(total) : 'N/A'}</Typography>
                </StyledSummaryDataRow>
                <Box sx={{ mt: 4 }}>
                  <FormikInput
                    variant="outlined"
                    color="secondary"
                    type="text"
                    name="notes"
                    noFormLabel
                    multiline
                    rows={4}
                    placeholder={t('note_placeholder')}
                    sx={{ width: '100%' }}
                  />
                </Box>
              </Box>

              <Button
                sx={{ mt: 4 }}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={onSubmit}
                disabled={disabled}>
                {inProgress ? (
                  <CircularProgress sx={{ color: 'primary.contrastText' }} />
                ) : (
                  t('submit_btn')
                )}
              </Button>
            </ContentCard>
          </Box>
        </Dialog>
      )}

      {/* Normal (Desktop) View */}
      {!isMobile && (
        <StyledSectionColumn sx={{ ...props.sx }}>
          <ContentCard sx={{ padding: theme.spacing(4) }}>
            <Box>
              <Typography variant="h5">{t('reservation_heading')}</Typography>
              {summaryItems?.map((item, idx) => {
                if (item.label && item.value) {
                  return (
                    <StyledSummaryDataRow key={`${item.label}${item.value}${idx}`}>
                      <Typography variant="body2" color={'text.secondary'}>
                        {item.label}
                      </Typography>
                      <Typography variant="body2" color={'text.primary'}>
                        {item.value}
                      </Typography>
                    </StyledSummaryDataRow>
                  );
                }
              })}
            </Box>

            <Box sx={{ display: Object.values(values.order).length > 0 ? 'block' : 'none' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 4, gap: 3 }}>
                <Typography variant="h5">{t('cost_heading')}</Typography>
                {Object.values(values.order)
                  ?.filter((v) => typeof v !== 'undefined')
                  .map((item, idx) => (
                    <FoodLineItem key={idx} {...item} editMode />
                  ))}
              </Box>
              <StyledSummaryDivider />
              <StyledSummaryDataRow sx={{ mt: 0 }}>
                <Typography variant="h5">{t('total')}</Typography>
                <Typography variant="h5">{total ? formatToCurrency(total) : 'N/A'}</Typography>
              </StyledSummaryDataRow>
              <Box sx={{ mt: 4 }}>
                <FormikInput
                  variant="outlined"
                  color="secondary"
                  type="text"
                  name="notes"
                  noFormLabel
                  multiline
                  rows={4}
                  placeholder={t('note_placeholder')}
                  sx={{ width: '100%' }}
                />
              </Box>
            </Box>

            <Button
              sx={{ mt: 4 }}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={onSubmit}
              disabled={disabled}>
              {inProgress ? (
                <CircularProgress sx={{ color: 'primary.contrastText' }} />
              ) : (
                t('submit_btn')
              )}
            </Button>
          </ContentCard>
        </StyledSectionColumn>
      )}

      {/* Success Dialog */}
      <Dialog open={open} onClose={() => false} maxWidth={'xs'} fullWidth={true}>
        <DialogTitle>{tDialog('title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{tDialog('description')}</DialogContentText>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant="h5">{tDialog('reservation_heading')}</Typography>
              {summaryItems?.map((item, idx) => {
                if (item.label && item.value) {
                  return (
                    <StyledSummaryDataRow key={`${item.label}${item.value}${idx}`} gap={2}>
                      <Typography variant="body2" color={'text.secondary'} flex={2}>
                        {item.label}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={'text.primary'}
                        flex={3}
                        textAlign={'right'}>
                        {item.value}
                      </Typography>
                    </StyledSummaryDataRow>
                  );
                }
              })}
              <StyledSummaryDataRow>
                <Typography variant="body2" color={'text.secondary'} flex={1}>
                  {tDialog('note')}
                </Typography>
                <Typography variant="body2" color={'text.primary'} flex={2} textAlign={'right'}>
                  {values.notes ? values.notes : '-'}
                </Typography>
              </StyledSummaryDataRow>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 2, gap: 3 }}>
              <Typography variant="h5">{tDialog('cost_heading')}</Typography>
              {Object.values(values.order)
                ?.filter((v) => typeof v !== 'undefined')
                .map((item, idx) => (
                  <FoodLineItem key={idx} {...item} />
                ))}
            </Box>
            <StyledSummaryDivider />
            <StyledSummaryDataRow sx={{ mt: 0 }}>
              <Typography variant="h5">{tDialog('total')}</Typography>
              <Typography variant="h5">{total ? formatToCurrency(total) : 'N/A'}</Typography>
            </StyledSummaryDataRow>
            <StyledSummaryDivider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
              <Typography variant="subtitle2" fontWeight={'bold'} flex={1}>
                {tDialog('waiver_question')}
              </Typography>
              <Button
                variant={'outlined'}
                size={'medium'}
                disabled={isWaiverLoading}
                onClick={() => sendWaiver({})}>
                {tDialog('waiver_button')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mt: 2, p: 4 }}>
          <Button
            type="button"
            onClick={completeReservation}
            color={'primary'}
            variant={'contained'}>
            {tDialog('book_another_appoinment')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FoodSummaryCard;
